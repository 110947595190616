import React from 'react'

import {
  BoxedContainer,
  Section,
  SectionHeader,
  SectionFooter,
  ThemeCardsGrid,
  Subtitle,
  ViewMoreButton,
  ConditionalDisplay,
} from '../../elements'
import ShowcaseCardMinimal from './ShowcaseCardMinimal'

const ShowcaseSectionMinimal = ({posts, bgColor, title, subtitle}) => {
  return (
    <Section padding="fixed" backgroundColor={bgColor}>
      <BoxedContainer>
        <SectionHeader>
          <div>
            <h2>{title}</h2>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </div>
          <ConditionalDisplay.HideOnMobile>
            <ViewMoreButton to="/showcase/">View showcase</ViewMoreButton>
          </ConditionalDisplay.HideOnMobile>
        </SectionHeader>
        <ThemeCardsGrid>
          {posts.map(post => (
            <ShowcaseCardMinimal
              post={post.node || post}
              key={post?.node?.id || post.id}
            />
          ))}
        </ThemeCardsGrid>
        <ConditionalDisplay.ShowOnMobile>
          <SectionFooter>
            <ViewMoreButton to="/showcase/">View showcase</ViewMoreButton>
          </SectionFooter>
        </ConditionalDisplay.ShowOnMobile>
      </BoxedContainer>
    </Section>
  )
}

export default ShowcaseSectionMinimal
