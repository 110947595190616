import {graphql} from 'gatsby'
import React from 'react'

import {Layout, MetaData} from '../components'
import {
  Hero,
  Themes,
  Features,
  Showcase,
  HomepageCTA,
} from '../sections/homepage'

const IndexPage = ({data}) => {
  return (
    <Layout>
      <MetaData />
      <Hero />
      <Themes />
      <Features />
      <Showcase posts={data.showcasePosts.edges} />
      <HomepageCTA />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    showcasePosts: allContentfulShowcasePost(
      limit: 3
      filter: {featured: {eq: true}}
    ) {
      edges {
        node {
          ...ContentfulShowcasePostFields
        }
      }
    }
  }
`
