import React from 'react'
import styled from 'styled-components'

import theme from '../styles/theme'

const FeatureGridItem = ({icon, title, description}) => {
  const Icon = icon
  return (
    <Wrapper>
      <div>
        <Icon
          fill={theme.color.primary.base}
          width="100%"
          style={{
            height: 'auto',
          }}
        />
      </div>
      <Content>
        <h3>{title}</h3>
        <p>{description}</p>
      </Content>
    </Wrapper>
  )
}

export default FeatureGridItem

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 47px auto;
  grid-gap: 25px;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: 52px auto;
    grid-gap: 20px;
  }
`

const Content = styled.div`
  h3 {
    color: ${props => props.theme.color.primary.base};
    margin: 0 0 8px;
  }

  p {
    color: ${props => props.theme.color.text.lightest};
  }
`
