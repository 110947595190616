import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import {BoxedContainer, Section} from '../../elements'

const CTA = ({title, bg, color, children}) => (
  <StyledSection padding="fixed" bg={bg} color={color}>
    <BoxedContainer>
      <Title>{title}</Title>
      <Grid>{children}</Grid>
    </BoxedContainer>
  </StyledSection>
)

export default CTA

CTA.propTypes = {
  title: PropTypes.string.isRequired,
  bg: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
}

const StyledSection = styled(Section)`
  background: ${props =>
    props.bg ? props.bg : props.theme.color.secondary.base};
  color: ${props => (props.color ? props.color : props.theme.color.inverted)};
`

const Title = styled.h2`
  margin: 0 0 50px;
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin: 0 0 60px;
  }
`

const Grid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  list-style: none;
  margin: -25px;

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    margin: -30px 0;
  }
`
