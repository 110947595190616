import styled, {css} from 'styled-components'
import {Link} from 'gatsby'

import theme from '../styles/theme'

import arrow from '../images/icons/arrow-right.svg'
import arrowWhite from '../images/icons/arrow-right--white.svg'

const styles = css`
  border-radius: 4px;
  font-size: 1.7rem;
  padding: 8px 16px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  height: 52px;

  @media (min-width: ${theme.breakpoints.xl}) {
    font-size: 1.8rem;
    height: 58px;
  }

  ${props =>
    props.large &&
    css`
      padding: 14px 15px;

      @media (min-width: ${theme.breakpoints.xl}) {
        padding: 15px 16px;
      }
    `}
`

const stylesPrimary = props => css`
  background-color: ${props.background
    ? props.background
    : props.theme.color.primary.base};
  border: 2px solid
    ${props.background ? props.background : props.theme.color.primary.base};
  color: ${props.color ? props.color : props.theme.color.inverted};

  &:hover {
    background-color: ${props.background
      ? props.background
      : props.theme.color.primary.lighter};
    border-color: ${props.background
      ? props.background
      : props.theme.color.primary.lighter};
  }
`

const stylesSecondary = props => css`
  background-color: transparent;
  border: 2px solid
    ${props.color ? props.color : props.theme.color.primary.base};
  color: ${props.color ? props.color : props.theme.color.primary.base};

  &:hover {
    background-color: ${props.theme.color.primary.base};
    color: ${props.theme.color.inverted};

    &::after {
      filter: brightness(10);
    }
  }
`

const iconStyles = props => css`
  &::after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 -2px 0 6px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    left: 0;

    ${props.witharrow &&
    css`
      background-image: url(${props.background ? arrow : arrowWhite});
      transition: left 0.2s ease-in-out;
    `}

    ${props.icon &&
    css`
      background-image: url(${props.icon});
    `}

    @media (min-width: ${theme.breakpoints.xl}) {
      width: 23px;
      height: 23px;
      margin: 0 -4px 0 6px;
    }
  }

  ${props.witharrow &&
  css`
    &:hover {
      &::after {
        left: 4px;
      }
    }
  `}
`

const round = css`
  border-radius: 29px;
  padding: 8px 22px;
`

export const LinkPrimary = styled(Link)`
  ${styles}
  ${stylesPrimary}
  ${props => (props.icon || props.witharrow ? iconStyles : '')}
`

export const LinkSecondary = styled(Link)`
  ${styles}
  ${stylesSecondary}
  ${props => (props.icon || props.witharrow ? iconStyles : '')}
`

export const ExternalLinkPrimary = styled.a`
  ${styles}
  ${stylesPrimary}
  ${props => (props.icon || props.witharrow ? iconStyles : '')}
  ${props => props.round && round}
`

export const ExternalLinkSecondary = styled.a`
  ${styles}
  ${stylesSecondary}
  ${props => (props.icon || props.witharrow ? iconStyles : '')}
`

export const ButtonPrimary = styled.button`
  ${styles}
  ${stylesPrimary}
  ${props => (props.icon || props.witharrow ? iconStyles : '')}
`

export const ButtonSecondary = styled.button`
  ${styles}
  ${stylesSecondary}
  ${props => (props.icon || props.witharrow ? iconStyles : '')}
`
