import React from 'react'

import {
  BoxedContainer,
  Section,
  SectionHeader,
  SectionFooter,
  ThemeCardsGrid,
  Subtitle,
  ViewMoreButton,
  ConditionalDisplay,
} from '../../elements'
import {ShowcaseCardMinimal} from '../../components/showcase'

const Showcase = ({posts}) => {
  return (
    <Section padding="fixed">
      <BoxedContainer>
        <SectionHeader>
          <div>
            <h2>See our themes in action</h2>
            <Subtitle>
              A growing collection of creatives using our Ghost themes
            </Subtitle>
          </div>
          <ConditionalDisplay.HideOnMobile>
            <ViewMoreButton to="/showcase/">View showcase</ViewMoreButton>
          </ConditionalDisplay.HideOnMobile>
        </SectionHeader>
        <ThemeCardsGrid>
          {posts.map(post => (
            <ShowcaseCardMinimal post={post.node} key={post.node.id} />
          ))}
        </ThemeCardsGrid>
        <ConditionalDisplay.ShowOnMobile>
          <SectionFooter>
            <ViewMoreButton to="/showcase/">View showcase</ViewMoreButton>
          </SectionFooter>
        </ConditionalDisplay.ShowOnMobile>
      </BoxedContainer>
    </Section>
  )
}

export default Showcase
