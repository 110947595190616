import React from 'react'

import theme from '../../styles/theme'
import CTA, {CTAItem} from '../../components/CTA'

import ToolsIcon from '../../images/inline-svgs/tools.svg'
import DocumentationIcon from '../../images/inline-svgs/documentation.svg'
import ContactIcon from '../../images/inline-svgs/chat.svg'

const HomepageCTA = () => (
  <CTA
    title="Ready to Get Started?"
    bg={theme.color.grey.lighter}
    color={theme.color.primary.base}
  >
    <CTAItem
      title="View themes"
      link="/themes/ghost/"
      color={theme.color.primary.base}
    >
      <ToolsIcon color={theme.color.primary.base} />
    </CTAItem>
    <CTAItem
      title="Documentation"
      link="https://docs.stylesheets.dev/"
      externalLink
      color={theme.color.primary.base}
    >
      <DocumentationIcon color={theme.color.primary.base} />
    </CTAItem>
    <CTAItem
      title="Contact us"
      link="/contact/"
      color={theme.color.primary.base}
    >
      <ContactIcon color={theme.color.primary.base} />
    </CTAItem>
  </CTA>
)

export default HomepageCTA
