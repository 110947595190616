import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'

import Arrow from '../../images/inline-svgs/arrow-right.svg'

const CTAItem = ({children, title, link, externalLink, color}) => (
  <Wrapper>
    {externalLink ? (
      <a href={link} target="_blank" rel="noreferrer">
        <LinkWrapper>
          <Icon>{children}</Icon>
          <Title color={color}>
            {title}
            <Arrow className="ctaitem__arrow" />
          </Title>
        </LinkWrapper>
      </a>
    ) : (
      <Link to={link}>
        <LinkWrapper>
          <Icon>{children}</Icon>
          <Title color={color}>
            {title}
            <Arrow className="ctaitem__arrow" />
          </Title>
        </LinkWrapper>
      </Link>
    )}
  </Wrapper>
)

export default CTAItem

CTAItem.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  externalLink: PropTypes.bool,
  color: PropTypes.string,
}

const Wrapper = styled.li`
  margin: 25px;
  width: 100%;
  min-width: 130px;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    width: auto;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    margin: 30px;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    .ctaitem__arrow {
      left: 8px;
    }
  }
`

const Icon = styled.div`
  width: 60px;
  margin: 0 0 12px;

  svg {
    width: 100%;
    height: auto;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    width: 65px;
  }
`

const Title = styled.p`
  color: ${props => (props.color ? props.color : props.theme.color.inverted)};
  display: flex;
  align-items: center;
  font-size: 2rem;

  svg {
    stroke: ${props =>
      props.color ? props.color : props.theme.color.inverted};
    position: relative;
    left: 4px;
    width: 25px;
    height: 25px;
    transition: all 0.2s;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    font-size: 2.2rem;
  }
`
