import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'

import {
  BoxedContainer,
  Section,
  SectionHeader,
  ViewMoreButton,
  ThemeCardsGrid,
} from '../../elements'
import ThemeCard from '../../components/ThemeCard'

const Themes = () => {
  const data = useStaticQuery(graphql`
    query {
      ampersand: file(relativePath: {eq: "themes/ampersand.png"}) {
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
      sapphire: file(relativePath: {eq: "themes/sapphire.png"}) {
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
      chronicles: file(relativePath: {eq: "themes/chronicles.png"}) {
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
      sublime: file(relativePath: {eq: "themes/sublime.png"}) {
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
      flamingo: file(relativePath: {eq: "themes/flamingo.png"}) {
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
      shuttle: file(relativePath: {eq: "themes/shuttle.png"}) {
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
    }
  `)

  return (
    <Section padding="fixed">
      <BoxedContainer>
        <SectionHeader>
          <h2>Latest Themes</h2>
          <ViewMoreButton to="/themes/ghost/">View all themes</ViewMoreButton>
        </SectionHeader>
        <ThemeCardsGrid>
          <ThemeCard
            image={data.ampersand}
            title="Ampersand"
            subtitle="Ghost 4.x"
            link="/themes/ghost/ampersand/"
            alt="Ampersand Ghost theme"
            height="320px"
            newRelease={true}
          />
          <ThemeCard
            image={data.sapphire}
            title="Sapphire"
            subtitle="Ghost 4.x"
            link="/themes/ghost/sapphire/"
            alt="Sapphire Ghost theme"
            height="320px"
          />
          <ThemeCard
            image={data.chronicles}
            title="Chronicles"
            subtitle="Ghost 4.x"
            link="/themes/ghost/chronicles/"
            alt="Chronicles ghost theme"
            height="320px"
          />
          <ThemeCard
            image={data.sublime}
            title="Sublime"
            subtitle="Ghost 4.x"
            link="/themes/ghost/sublime/"
            alt="Sublime ghost theme"
            height="320px"
          />
          {/* <ThemeCard
            image={data.elementary}
            title="Elementary"
            subtitle="Ghost"
            link="/themes/ghost/elementary/"
            alt="Elementary ghost theme"
            height="320px"
          /> */}
          <ThemeCard
            image={data.shuttle}
            title="Shuttle"
            subtitle="Ghost 4.x"
            link="/themes/ghost/shuttle/"
            alt="Shuttle ghost theme"
            height="320px"
          />
          <ThemeCard
            image={data.flamingo}
            title="Flamingo"
            subtitle="Ghost 4.x"
            link="/themes/ghost/flamingo/"
            alt="Flamingo ghost theme"
            height="320px"
          />
        </ThemeCardsGrid>
      </BoxedContainer>
    </Section>
  )
}

export default Themes
