import React from 'react'
import styled from 'styled-components'
import {StaticImage} from 'gatsby-plugin-image'

import {BoxedContainer} from '../../elements'
import {LinkPrimary} from '../../elements/Buttons'
import theme from '../../styles/theme'

const Hero = () => (
  <Wrapper>
    <BoxedContainer>
      <HeroContent>
        <h1>
          We design and develop{' '}
          <a href="https://ghost.org" rel="noopener noreferrer" target="_blank">
            Ghost themes
          </a>{' '}
          for creatives and brands.{' '}
        </h1>
        <LinkPrimary
          to="/themes/ghost/"
          background={theme.color.inverted}
          color={theme.color.primary.base}
          witharrow="true"
        >
          Explore Themes
        </LinkPrimary>
      </HeroContent>
      <HeroImage>
        <StaticImage
          src="../../images/homepage/hero-image.png"
          alt=""
          placeholder="blurred"
          loading="eager"
          width={900}
          layout="constrained"
        />
      </HeroImage>
    </BoxedContainer>
  </Wrapper>
)

export default Hero

const Wrapper = styled.section`
  background: ${props => props.theme.color.primary.base};
  color: ${props => props.theme.color.inverted};

  ${BoxedContainer} {
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: ${theme.breakpoints.md}) {
      grid-template-columns: 43% 57%;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
      grid-template-columns: 40% 60%;
    }

    @media (min-width: ${theme.breakpoints.xl}) {
      grid-template-columns: 35% 65%;
    }
  }
`

const HeroContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 30px 0;

  h1 {
    margin: 0 0 20px;

    a {
      color: ${theme.color.inverted};
      &:hover {
        border-bottom: 1px solid ${theme.color.inverted};
      }
    }
  }

  @media (min-width: ${theme.breakpoints.md}) {
    h1 {
      margin: -20% 0 30px;
    }
  }
`

const HeroImage = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 40px;

  @media (min-width: ${theme.breakpoints.xl}) {
    padding-top: 70px;
  }
`
