import React from 'react'
import styled from 'styled-components'

import BrowserWindow from '../BrowserWindow'

const ShowcaseCardMinimal = ({post}) => (
  <Wrapper href={post.link} target="_blank" rel="noopener noreferrer">
    {post.screenshot && (
      <BrowserWindow
        image={post.screenshot}
        alt={`${post.title} website screenshot`}
        colorTheme="light"
        height="340px"
      />
    )}
    <Content>
      <div>
        {post.title && <Title>{post.title}</Title>}
        {post.themeName && (
          <Subtitle>
            Using {post.themeName} {post.themeCategory} theme
          </Subtitle>
        )}
      </div>
    </Content>
  </Wrapper>
)

export default ShowcaseCardMinimal

const Wrapper = styled.a`
  color: ${props => props.theme.color.text.base};
`

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px 0 0;
`

const Title = styled.h3`
  font-size: 1.8rem;
  margin: 0;
`

const Subtitle = styled.p`
  font-size: 1.6rem;
  margin: 2px 0 0;
  color: ${props => props.theme.color.text.lightest};
`
