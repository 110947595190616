import React from 'react'
import styled from 'styled-components'

import {Section, BoxedContainer} from '../../elements'
import FeatureGridItem from '../../components/FeatureGridItem'

import Customize from '../../images/inline-svgs/design.svg'
import Support from '../../images/inline-svgs/faq.svg'
import Responsive from '../../images/inline-svgs/responsive.svg'
import Updates from '../../images/inline-svgs/download-from-cloud.svg'
import Docs from '../../images/inline-svgs/docs.svg'
import Developer from '../../images/inline-svgs/developer.svg'

const Features = () => (
  <Wrapper padding="fixed">
    <BoxedContainer>
      <h2 className="visually-hidden">Features</h2>
      <Grid>
        <FeatureGridItem
          icon={Customize}
          title="Customization Friendly"
          description="Our themes are fully documented, and use latest standards to make it easy for you to customize the theme the way you want to it."
        />
        <FeatureGridItem
          icon={Support}
          title="Quick Support"
          description="We are aways reachable through email to help you out with any issues or help you need. "
        />
        <FeatureGridItem
          icon={Responsive}
          title="Responsive Design"
          description="Great user experience across all devices. We use latest tests and standards to fully test our themes before releasing them into the market. "
        />
        <FeatureGridItem
          icon={Updates}
          title="Free Updates"
          description="We make sure our themes are always updated and compatible with latest cms version. The updates are provided to existing customers for free as well. "
        />
        <FeatureGridItem
          icon={Docs}
          title="Fully Documented"
          description="Our themes are fully documented, and use latest standards to make it easy for you to customize the theme the way you want it."
        />
        <FeatureGridItem
          icon={Developer}
          title="Hire us"
          description="We provide customization services for our themes to help you match them to your brand identity. "
        />
      </Grid>
    </BoxedContainer>
  </Wrapper>
)

export default Features

const Wrapper = styled(Section)`
  background: ${props => props.theme.color.grey.lighter};
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px 35px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
  }
`
